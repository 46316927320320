import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TRACK_EVENT, getAuthenticatedUserType, userTypes, logout } from '@tkww/the-bash-frontend';
import { useRouter } from 'next/router';

import { getAuthenticationToken } from 'api/users';
import cookieHelper from 'helpers/cookieHelper';
import { gmConfig } from 'helpers/config';
import { CTA_CLICK } from 'analytics/constants';
import useQueryParameters from 'hooks/useQueryParameters';

import AuthenticationSection from 'components/customSections/AuthenticationSection';

import LoginForm from 'views/LoginPage/LoginForm';
import LoggingIn from 'views/LoginPage/LoggingIn';

const DEFAULT_DATA_STATE = {
  username: '',
  password: '',
  keepUserLoggedIn: false,
};

const DEFAULT_ERRORS_STATE = {
  username: '',
  password: '',
  general: '',
};

export const TITLE = 'Log In';
export const INITIAL_SUBTITLE =
  'Welcome back to the party! Whether you are a planner or a vendor, you can log in below.';

const LoginPageView = () => {
  const router = useRouter();
  const { getQueryParameterValue } = useQueryParameters();
  const dispatch = useDispatch();
  const [data, setData] = useState(DEFAULT_DATA_STATE);

  const [errors, setErrors] = useState(DEFAULT_ERRORS_STATE);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(!!router.query.t);

  const authenticationToken = useRef(router.query.t);

  const handleResetPasswordTracking = () => {
    dispatch({
      type: TRACK_EVENT,
      name: CTA_CLICK,
      properties: {
        clickArea: 'forgot username or password',
        clickTarget: 'hyperlink',
      },
    });
  };

  const handleUserTracking = (userType) => {
    const userTypeForTracking = userType === userTypes.CLIENT ? 'planner' : userType;

    dispatch({
      type: TRACK_EVENT,
      name: 'Successful Login',
      properties: {
        userType: userTypeForTracking,
      },
    });
  };

  const handleAuthenticationCookies = (authenticationDetails) => {
    const { access_token: accessToken, refresh_token: refreshToken, scope } = authenticationDetails;
    const cookieExpirationLength = data.keepUserLoggedIn ? 30 : null;

    // This is a hack to get the person's "Welcome Name" greeting out of the scopes, since DNOA doesn't
    // allow passing additional data in the JSON response.
    const scopes = scope.split(' ');
    const scopeElement = scopes.find((s) => s.indexOf('WelcomeName') > -1);

    cookieHelper.set(
      'WelcomeName',
      decodeURIComponent(scopeElement.split(':')[1]),
      cookieExpirationLength
    );
    cookieHelper.set('BearerToken', accessToken, cookieExpirationLength);
    cookieHelper.set('RefreshToken', refreshToken, cookieExpirationLength);
    cookieHelper.set('Scopes', btoa(scope), cookieExpirationLength);
  };

  const getReturnUrl = () => {
    const returnUrlParam = getQueryParameterValue('returnUrl');

    if (returnUrlParam != null && returnUrlParam[0] === '/' && returnUrlParam[1] !== '/') {
      return returnUrlParam.replace(/memberid/gi, 'memberID').replace(/returnurl/gi, 'ReturnURL');
    }

    return returnUrlParam;
  };

  const getRedirectUrl = (userType) => {
    const returnUrl = getReturnUrl();
    const { domains } = gmConfig;
    let baseDomain = null;
    let redirectUrl = null;

    if (![userTypes.MEMBER, userTypes.CLIENT].includes(userType)) {
      throw new Error(`Invalid user type: ${userType}`);
    }

    if (userType === userTypes.MEMBER) {
      baseDomain = `https://${domains.mcp}`;
    } else if (userType === userTypes.CLIENT) {
      baseDomain = `https://${domains.ccp}`;
    }

    if (returnUrl) {
      redirectUrl = `${baseDomain}${returnUrl}`;
    } else if (userType === userTypes.MEMBER) {
      redirectUrl = `${baseDomain}/${gmConfig.mcpMembershipSelectionUrl}`;
    } else if (userType === userTypes.CLIENT) {
      redirectUrl = `${baseDomain}/events`;
    }

    return redirectUrl;
  };

  const handleLogin = async () => {
    const { current: token } = authenticationToken;

    try {
      setErrors(DEFAULT_ERRORS_STATE);
      setIsProcessing(true);

      const username = token ? 'token' : data.username;
      const password = token || data.password;
      const responseData = await getAuthenticationToken(username, password);

      setIsAuthenticated(true);
      const userType = getAuthenticatedUserType(responseData.scope);
      handleUserTracking(userType);
      handleAuthenticationCookies(responseData);
      window.location.href = getRedirectUrl(userType);
    } catch (err) {
      const requestErrors = {};
      if (!token) {
        if (!data.username) {
          requestErrors.username = 'Please enter your username.';
        }

        if (!data.password) {
          requestErrors.password = 'Please enter your password.';
        }

        if (!requestErrors.username && !requestErrors.password) {
          requestErrors.general =
            'This username and password combination is invalid. Please try again or use the link below to reset your info.';
        }
      } else {
        authenticationToken.current = null;
        let queryStringParams = {};
        const returnUrl = getReturnUrl();
        if (returnUrl) {
          queryStringParams = {
            returnUrl,
          };
        }

        router.replace(
          {
            pathName: '/login',
            query: queryStringParams,
          },
          undefined,
          { shallow: true }
        );
      }

      setIsAuthenticated(false);
      setErrors(requestErrors);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    logout(cookieHelper);
  }, []);

  useEffect(() => {
    if (authenticationToken.current) {
      handleLogin();
    }
  }, [authenticationToken]);

  return (
    <AuthenticationSection title={TITLE} subtitle={isAuthenticated ? '' : INITIAL_SUBTITLE}>
      {isAuthenticated ? (
        <LoggingIn />
      ) : (
        <LoginForm
          data={data}
          errors={errors}
          isProcessing={isProcessing}
          handleChange={setData}
          handleLogin={handleLogin}
          handleResetPasswordTracking={handleResetPasswordTracking}
        />
      )}
    </AuthenticationSection>
  );
};

export default LoginPageView;
